import { Paper, Stack, TextField, Typography, Box, Alert, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Logo } from 'images/Logo';
import { Link, WaitButton } from 'components';
import { Form, mustBeEmail, passwordPolicy } from 'components/form';
import { AuthPage } from 'app/layout';
import { useAsyncState } from 'app/hooks';
import { useAppDispatch, signup } from 'app/store';

import type { Validate } from 'components/form';
import type { Credential } from 'app/store';

type SignupData = {
  email: string;
  password: string;
};

const credentialValidator: Record<string, Validate> = {
  email: mustBeEmail(),
  password: passwordPolicy(),
};

export default function Signup() {
  const dispatch = useAppDispatch();
  const [signupState, success, error, waiting] = useAsyncState();
  const handleSubmit = (data: SignupData) => {
    dispatch(signup(data)).unwrap().then(success, error);
    waiting();
  };

  return (
    <AuthPage>
      <Logo />
      <Paper sx={{ my: 4, px: 4, py: 6, width: { xs: '90%', sm: 500 } }}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="h5">SIGN UP</Typography>
          <Typography color="textSecondary" paragraph>
            With email and password
          </Typography>
          {signupState.status === 'failure' && (
            <Alert severity="error">{signupState.error.code || 'Signup failed'}</Alert>
          )}
        </Box>

        <Form<Partial<SignupData>>
          initialData={{}}
          onSubmit={(data) => handleSubmit(data as Credential)}
          validators={credentialValidator}>
          {(state, updateField) => {
            return (
              <Stack spacing={3}>
                <TextField
                  id="email"
                  value={state.data.email || ''}
                  onChange={(e) => updateField('email', e.target.value)}
                  error={Boolean(state.errors['email'])}
                  helperText={state.errors['email']}
                  required
                  fullWidth
                  label="Email"
                  autoComplete="username"
                />
                <TextField
                  id="password"
                  type="password"
                  value={state.data.password || ''}
                  onChange={(e) => updateField('password', e.target.value)}
                  error={Boolean(state.errors['password'])}
                  helperText={state.errors['password']}
                  required
                  fullWidth
                  label="Password"
                  autoComplete="current-password"
                />

                <WaitButton id="submit" type="submit" variant="contained" wait={signupState.status === 'pending'}>
                  Sign up
                </WaitButton>
              </Stack>
            );
          }}
        </Form>
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Typography>
            Already have an account? <Link href="/auth/signin">Sign in</Link>
          </Typography>
        </Box>
      </Paper>
    </AuthPage>
  );
}
